import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import Apps from "../../../components/apps";

const AppsGooglePlayPage = () => {
    const {allAppsYaml} = useStaticQuery(
        graphql`
          query {
            allAppsYaml {
              edges {
                node {
                  id
                  name
                  description
                  image
                  store {
                    googleplay
                  }
                }
              }
            }
           }
    `
    )
    const apps = allAppsYaml.edges.map((e) => e.node).filter((app) => app.store.googleplay !== null)
    return (
        <Layout isTop={false}>
            <Seo title="Androidアプリ一覧"
                 description="nullhouseのAndroidアプリ一覧です。"/>
            <Apps apps={apps} storeType="googleplay"/>
        </Layout>
    )
}

export default AppsGooglePlayPage
